import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Dropdown, Menu } from "antd";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import Logo from "assets/images/img/Morfius_NewLogo_Black_Grey.png";

import { useAppContext } from "context/appContext";
import { LS } from "utils/utils";

import Overlay from "./overlay";
import Icon from "components/icon";

import Styles from "./styles/header.module.scss";
import DesktopIcon from "./DesktopIcon";

const Header = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated, meData } = useAppContext();
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);

  const handleMenuClick = (menu) => {
    console.log("Menu", menu)
    if (menu.key === 'help') {
      window.open("https://morfius.atlassian.net/wiki/external/YzQ1Mzg1OTZmYjcwNGM5MDgyNDNlYTI1MjI4YzA1Mzk", "_blank");
      return;
    }
    if (menu.item.props.link) {
      navigate(menu.item.props.link);
    }
  };

  const handleActiveKey = () => {
    const linkArray = window.location.href.split("#/");
    if (linkArray[linkArray.length - 1] === "") {
      return "home";
    }
    return linkArray[linkArray.length - 1];
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "modalOpen") {
        setIsChildModalOpen(true);
      } else {
        setIsChildModalOpen(false);
      }
    });
  }, []);
  console.log("Active key", handleActiveKey())
  const activeKey = handleActiveKey();
  return (
    <div className={Styles.header}>
      <div className={Styles.logo}>
        <Link to="/">
          <img src={Logo} alt="" />
        </Link>
      </div>
      <div className={Styles.menu}>
        <Menu
          mode="horizontal"
          activeKey={activeKey}
          onClick={handleMenuClick}
          items={[
            {
              label: "Desktop",
              key: "home",
              className: "desktop-icon",
              link: "/",
              icon: (
                <DesktopIcon
                  color={activeKey === "home" ? "#0991f2" : "#989898"}
                />
              ),
            },
            meData &&
            meData.org_admin && {
              label: "Administration",
              key: "administration",
              link: "/administration",
              icon: <AnalyticsOutlinedIcon />,
            },
            {
              label: "Help",
              key: "help",
              icon: <ChatBubbleOutlineOutlinedIcon />,
            },
          ]}
          className={Styles["menu-items"]}
        />
      </div>
      <div className={Styles["user-profile"]}>
        <Dropdown
          overlayClassName={Styles["dropdown"]}
          menu={{
            items: [
              {
                key: "1",
                label: "Log Out",
                icon: <LogoutOutlinedIcon className={Styles.icon} />,
                onClick: () => {
                  LS.delete("session");
                  LS.delete("user");
                  setIsAuthenticated(false);
                  navigate("/");
                },
              },
            ],
          }}
          trigger={["click"]}
        >
          <div className={Styles["avatar-wrapper"]}>
            <p>
              Welcome {meData?.user}
              <span>{meData?.org_admin ? "Admin" : "Manager"}</span>
            </p>
            <Avatar size={44} />
          </div>
        </Dropdown>
      </div>
      <Overlay open={isChildModalOpen} />
    </div>
  );
};

export default Header;
