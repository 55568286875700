import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Col, Row, Switch, Tooltip } from "antd";
import { useState } from "react";

// import Styles from "./styles/settings.module.scss";
const Settings = () => {
  const [isBackupEnabled, setIsBackupEnabled] = useState(false);

  const handleToggle = (checked) => {
    setIsBackupEnabled(checked);
    // Add logic to start or stop backup based on the toggle state
  };

  return (
    <div style={{ padding: 24 }}>
      <Row align="middle" gutter={16}>
        <Col>
          <label style={{ fontWeight: 500 }}>Enable Automatic Backup</label>
        </Col>
        <Col>
          <Switch
            checked={isBackupEnabled}
            onChange={handleToggle}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
